// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2020 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See docs/COPYRIGHT.rdoc for more details.
// ++
import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { I18nService } from "core-app/core/i18n/i18n.service";
import { SupportPlan } from '../billing-form/billing.service';
import { populateInputsFromDataset } from 'core-app/shared/components/dataset-inputs';


@Component({
  templateUrl: './subscription-status.component.html',
  selector: 'subscription-status',
  styleUrls: ['./subscription-status.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionStatusComponent implements OnInit {
  public isTrial:boolean = false;
  public status:string = 'active';
  public withScheduledChanges:boolean = false;
  public upgradeUrl:string = '';
  public supportPlan:SupportPlan;

  public text = {
    status:
      this.I18n.t('js.subscription.label_status'),
    noActiveSubscription:
      (upgradeUrl:string) => this.I18n.t('js.subscription.text_no_active_subscription', {upgrade_url: upgradeUrl}),
    subscriptionStatus:
      (status:string, supportPlan:SupportPlan) => this.I18n.t(
        'js.subscription.text_subscription_status_' + status,
        {subscription: this.I18n.t('js.subscription.text_subscription_support_level_' + supportPlan)}
      ),
    defaultSubscriptionStatus:
      (status:string) => this.I18n.t(
        'js.subscription.text_subscription_status_' + status,
        {subscription: this.I18n.t('js.subscription.text_subscription_without_support')}
      ),
    subscriptionStatusUpdated:
      this.I18n.t('js.subscription.text_subscription_status_updated'),
  };

  constructor(
    readonly elementRef:ElementRef,
    private I18n:I18nService
  ) {
    populateInputsFromDataset(this);
  }

  ngOnInit() {
    this.isTrial = this.elementRef.nativeElement.dataset['trial'] === 'true';
    this.status = this.elementRef.nativeElement.dataset['status'];
    this.withScheduledChanges = this.elementRef.nativeElement.dataset['withScheduledChanges'] === 'true';
    this.upgradeUrl = this.elementRef.nativeElement.dataset['upgradeUrl'];
    this.supportPlan = this.elementRef.nativeElement.dataset['supportPlan'];
  }

  subscriptionText():string {
    if (this.supportPlan.length === 0) {
      return this.text.defaultSubscriptionStatus(this.status)
    } else {
      return this.text.subscriptionStatus(this.status, this.supportPlan)
    }
  }
}
