import {Injectable} from "@angular/core";

export interface CountryOption {
  name:string;
  key:string;
}

export interface SubscriptionPeriodOption {
  name:string;
  key:number;
  savedMonths:number;
}

export type SupportPlan = 'basic'|'professional'|'premium';

export type Edition = 'classic'|'bim';

@Injectable()
export class BillingService {
  private _currentCountryOption:CountryOption;
  private _availableCountryOptions:CountryOption[];
  private _currentSubscriptionPeriod:SubscriptionPeriodOption;
  private _availableSubscriptionPeriods:SubscriptionPeriodOption[];
  private _currentSupportPlan:SupportPlan = 'basic';
  private _userCount:number = 5;
  private _currency:string = 'EUR';
  private _basePrice:number = 5.95;
  private _edition:Edition = 'classic';

  get currentCountryOption():CountryOption {
    return this._currentCountryOption;
  }

  set currentCountryOption(value:CountryOption) {
    this._currentCountryOption = value;
  }

  updateCountryOptionByKey(key:string) {
    this.currentCountryOption = this.findCurrentCountry(key);
    if(!this.currentCountryOption) {
      // set default if language setting does not match any of the country codes
      this.currentCountryOption = this.findCurrentCountry('DE');
    }
  }

  get availableCountryOptions():CountryOption[] {
    return this._availableCountryOptions;
  }

  set availableCountryOptions(value:CountryOption[]) {
    this._availableCountryOptions = value;
  }

  private findCurrentCountry(key:string) {
    return _.find(this.availableCountryOptions, o => _.includes(key, o.key))!;
  }

  get userCount():number {
    return this._userCount;
  }

  set userCount(value:number) {
    this._userCount = value;
  }

  get currency():string {
    return this._currency;
  }

  set currency(value:string) {
    this._currency = value;
  }

  get basePrice():number {
    return this._basePrice;
  }

  set basePrice(value:number) {
    this._basePrice = value;
  }

  get currentSubscriptionPeriod():SubscriptionPeriodOption {
    return this._currentSubscriptionPeriod;
  }

  set currentSubscriptionPeriod(val:SubscriptionPeriodOption) {
    this._currentSubscriptionPeriod = val;
  }

  public updateSubscriptionPeriodByKey(key:number) {
    this.currentSubscriptionPeriod = _.find(this.availableSubscriptionPeriods, o => o.key === Number(key))!;
  }

  get availableSubscriptionPeriods():SubscriptionPeriodOption[] {
    return this._availableSubscriptionPeriods;
  }

  set availableSubscriptionPeriods(val:SubscriptionPeriodOption[]) {
    this._availableSubscriptionPeriods = val;
  }

  get currentSupportPlan():SupportPlan {
    return this._currentSupportPlan;
  }

  set currentSupportPlan(val:SupportPlan) {
    this._currentSupportPlan = val;
  }

  get edition():Edition {
    return this._edition;
  }

  set edition(val:Edition) {
    this._edition = val;
  }
}