// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++
import { Input, Component, ElementRef } from "@angular/core";
import { I18nService } from "core-app/core/i18n/i18n.service";
import { populateInputsFromDataset } from 'core-app/shared/components/dataset-inputs';

@Component({
  selector: 'trial-activate',
  templateUrl: './trial-activate.html',
  styleUrls: ['./trial-activate.sass'],
})
export class TrialActivateComponent {
  @Input() public username:string;
  @Input() public passwordMinLength:number;
  @Input() public passwordRequiredClassCount:number;
  @Input() public passwordActiveRules:string;
  @Input() public trialActivateImageSrc:string;

  public welcome:string = '';

  public text = {
    welcome: this.I18n.t('js.subscription.trial.welcome'),
    intro: this.I18n.t('js.subscription.trial.intro'),
    prompt: this.I18n.t('js.subscription.trial.prompt'),
  };

  public constructor(
    readonly elementRef:ElementRef,
    private readonly I18n:I18nService) {
    populateInputsFromDataset(this);
    this.firstNameChange('')
  }

  firstNameChange(firstName:string) {
    this.welcome = `${['Hi', firstName.trim()].join(' ')}…`;
  }
}
