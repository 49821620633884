<div class="form--field -required">
  <label class="form--label -required" for="subscription-support">
    <a [href]="pricingURL()"
       target="_blank"
       class="icon-context icon-info1"
    >
    </a>
    <span [textContent]="text.support">
    </span>
  </label>
  <div class="form--field-container">
    <div class="form--select-container -middle">
      <select
        (input)="updateSupportPlan($event.target.value)"
        name="subscription-support"
        id="subscription-support"
        class="form--select"
        required
      >
        <option
          *ngFor="let option of availableSupportPlanOptions"
          [textContent]="option.name"
          [selected]="billing.currentSupportPlan === option.key"
          [value]="option.key"
        >
        </option>
      </select>
    </div>
  </div>
</div>

<div class="form--field -required">
  <label class="form--label -required" for="subscription-users">
    <span [textContent]="text.users">
    </span>
  </label>
  <div class="form--field-container">
    <div class="form--select-container -middle">
      <select (input)="updateUserCount($event.target.value)"
             name="subscription-users"
             id="subscription-users"
             class="form--select"
             required>
        <option *ngFor="let option of availableUserCounts"
                [textContent]="option.name"
                [selected]="billing.userCount === option.key"
                [value]="option.key">
        </option>
      </select>
    </div>
  </div>
</div>

<div class="form--field -required">
  <label class="form--label subscription--period-label" for="subscription-period">
    <span class="form--label"
          [textContent]="text.period">
    </span>

    <span *ngIf="displayYearlySavingsText"
          class="subscription--period-saving"
          [textContent]="yearlySavedMonthsText">
    </span>
  </label>
  <div class="form--field-container">
    <div class="form--select-container -middle">
      <select
        (input)="updateSubscriptionPeriod($event.target.value)"
        name="subscription-period"
        id="subscription-period"
        class="form--select"
        required
      >
        <option
          *ngFor="let option of billing.availableSubscriptionPeriods"
          [textContent]="option.name"
          [selected]="billing.currentSubscriptionPeriod.key == option.key"
          [value]="option.key"
        >
        </option>
      </select>
    </div>
  </div>
</div>

<div class="form--field" *ngIf="allowBimEditionToggle">
  <label class="form--label" for="bim-edition-toggle">
    <span [textContent]="text.bimEdition">
    </span>
    &ngsp;
    <span class="subscription--addon-price"
          [textContent]="bimEditionCharge()">
    </span>
  </label>
  <div class="form--field-container">
    <div class="form--check-box-container">
      <label for="bim-edition-toggle" class="form--label">
        <input
          id="bim-edition-toggle"
          type="checkbox"
          class="form--check-box"
          [checked]="edition === 'bim'"
          (change)="updateEdition($event.target.checked)"
        >
      </label>
    </div>
  </div>
</div>

<div class="form--field -required">
  <label class="form--label -required" for="subscription-country">
    <span [textContent]="text.country">
    </span>
  </label>
  <div class="form--field-container">
    <div class="form--select-container -middle">
      <select (change)="updateCountryOption($event.target.value)"
              name="subscription-country"
              id="subscription-country"
              class="form--select"
              required>
        <option *ngFor="let option of billing.availableCountryOptions"
                [textContent]="option.name"
                [selected]="billing.currentCountryOption.name === option.name"
                [value]="option.key">
        </option>
      </select>
    </div>
  </div>
</div>

<div class="form--field -bold">
  <label class="form--label" for="billing-amount">
      <strong [textContent]="text.amount">
      </strong>
      <span>&nbsp;</span>
      <i [textContent]="text.vat_info"
         class="-small-font">
      </i>
  </label>
  <div class="form--field-container" >
    <span id="billing-amount"
          class="billing--amount"
          [textContent]="formattedBillingAmount">
    </span>
    <span *ngIf="displayYearlySavingsText"
          class="subscription--period-saving"
          [textContent]="yearlySavingsText">
    </span>
  </div>
</div>