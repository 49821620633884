<section class="form--section">
  <h3 class="form--section-title"
      *ngIf="headerTitle"
      [textContent]="headerTitle">
  </h3>

  <div class="form--field -required">
    <label class="form--label -required" for="invoice-title">
      <span [textContent]="text.title">
      </span>
    </label>
    <div class="form--field-container">
      <div class="form--select-container -xslim">
        <select name="invoice-title" id="invoice-title" class="form--select" required>
          <option *ngFor="let option of availableGenderOptions"
                  [textContent]="option"
                  [value]="option">
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="form--field -required">
    <label class="form--label -required" for="invoice-first-name">
      <span [textContent]="text.firstName">
      </span>
    </label>
    <div class="form--field-container">
      <div class="form--text-field-container -middle">
        <input [placeholder]="text.firstName" name="invoice-first-name" id="invoice-first-name" class="form--text-field" required type="text">
      </div>
    </div>
  </div>

  <div class="form--field -required">
    <label class="form--label -required" for="invoice-last-name">
      <span [textContent]="text.lastName">
      </span>
    </label>
    <div class="form--field-container">
      <div class="form--text-field-container -middle">
        <input [placeholder]="text.lastName" name="invoice-last-name" id="invoice-last-name" class="form--text-field" required type="text">
      </div>
    </div>
  </div>

  <div class="form--field -required">
    <label class="form--label -required" for="invoice-company">
        <span [textContent]="text.company">
        </span>
    </label>
    <div class="form--field-container">
      <div class="form--text-field-container -middle">
        <input [placeholder]="text.company" name="invoice-company" id="invoice-company" class="form--text-field" type="text" required>
      </div>
    </div>
  </div>

  <div class="form--field -required">
    <label class="form--label -required" for="invoice-billing-address">
      <span [textContent]="text.billingAddress">
      </span>
    </label>
    <div class="form--field-container">
      <div class="form--text-field-container -middle">
        <input [placeholder]="text.billingAddress" name="invoice-billing-address" id="invoice-billing-address" required class="form--text-field" type="text">
      </div>
    </div>
  </div>

  <div class="form--field -required">
    <label class="form--label -required" for="invoice-zip-code">
      <span [textContent]="text.zipCode + ' / ' + text.city">
      </span>
    </label>
    <div class="form--field-container">
      <div class="form--text-field-container -xslim">
        <input [placeholder]="text.zipCode" name="invoice-zip-code" id="invoice-zip-code" required class="form--text-field" type="text">
      </div>
      <div class="form--text-field-container -slim">
        <input [placeholder]="text.city" name="invoice-city" id="invoice-city" required class="form--text-field" type="text">
      </div>
    </div>
  </div>

  <div class="form--field -required">
    <label class="form--label -required" for="invoice-email">
      <span [textContent]="text.email">
      </span>
    </label>
    <div class="form--field-container">
      <div class="form--text-field-container -middle">
        <input [placeholder]="text.email" name="invoice-email" id="invoice-email" required class="form--text-field" type="email">
      </div>
    </div>
  </div>

  <div *ngIf="showVat" class="form--field">
    <label class="form--label" for="invoice-vat-id-number">
        <span [textContent]="text.vatIdNumber">
        </span>
    </label>
    <div class="form--field-container">
      <div class="form--field-affix">
        <span [textContent]="currentCountryOption.key"></span>
      </div>
      <div class="form--text-field-container -middle">
        <input [placeholder]="text.vatIdNumber" name="invoice-vat-id-number" id="invoice-vat-id-number" class="form--text-field" type="text">
      </div>
    </div>
  </div>

  <div class="form--field -required -trailing-label">
    <label class="form--label -visible-overflow" for="check-terms-of-service">
      <span [innerHTML]="text.agree"></span>
      <span *ngIf="showTermsOfServiceTooltip"
            class="tooltip--right"
            [attr.data-tooltip]="text.acceptedTerms">
        <i class="icon icon-info1"></i>
      </span>
    </label>
    <div class="form--field-container">
      <div class="form--check-box-container">
        <input id="check-terms-of-service" type="checkbox" class="form--check-box" required>
      </div>
    </div>
  </div>
</section>
