<div class="gdpr-page">
  <section class="gdpr-page--dpa-form-container">
    <div class="gdpr-page--dpa-form-introduction" *ngIf="!isSigned">
      <p [innerHTML]="text.introduction"></p>
      <button class="button -highlight gdpr-page--button" (click)="openDPAModal()">{{ text.reviewDPA }}</button>
    </div>

    <form class="form -bordered gdpr-page--dpa-form" [formGroup]="dpaForm">
      <div *ngIf="!isSigned; else signed">
        <h3 class="gdpr-page--heading">{{ text.form.label }}</h3>
        <section class="form--section">
          <div class="form--field -wide-label -required">
            <label class="form--label">{{ text.form.firstName }}</label>
            <div class="form--field-container">
              <div class="form--text-field-container">
                <input type="text" class="form--text-field" formControlName="firstName">
              </div>
            </div>
          </div>
          <div class="form--field -wide-label -required">
            <label class="form--label">{{ text.form.lastName }}</label>
            <div class="form--field-container">
              <div class="form--text-field-container">
                <input type="text" class="form--text-field" formControlName="lastName">
              </div>
            </div>
          </div>
          <div class="form--field -wide-label -required">
            <label class="form--label">{{ text.form.organization }}</label>
            <div class="form--field-container">
              <div class="form--text-field-container">
                <input type="text" class="form--text-field" formControlName="organization">
              </div>
            </div>
          </div>
          <div class="form--field -wide-label">
            <label class="form--label">{{ text.form.email }}</label>
            <div class="form--field-container">
              <div class="form--text-field-container">
                <input type="text" class="form--text-field" formControlName="email">
              </div>
            </div>
            <div *ngIf="dpaForm.controls.email.errors" class="form--field-instructions">{{ text.invalidMail }}</div>
          </div>
          <div class="form--field -wide-label -required">
            <label class="form--label">{{ text.form.address }}</label>
            <div class="form--field-container">
              <div class="form--text-field-container">
                <input type="text" class="form--text-field" formControlName="address">
              </div>
            </div>
          </div>
          <div class="form--field -wide-label -required">
            <label class="form--label">{{ text.form.zipCode + ' / ' + text.form.city }}</label>
            <div class="form--field-container">
              <div class="form--text-field-container -xslim">
                <input type="text" class="form--text-field" formControlName="zip">
              </div>
              <div class="form--text-field-container">
                <input type="text" class="form--text-field" formControlName="city">
              </div>
            </div>
          </div>
          <div class="form--field -wide-label -required">
            <label class="form--label">{{ text.form.country }}</label>
            <div class="form--field-container">
              <div class="form--select-container">
                <select class="form--select" formControlName="country">
                  <option *ngFor="let country of availableCountries" [ngValue]="country.key">{{ country.name }}</option>
                </select>
              </div>
            </div>
          </div>

          <label class="form--label-with-check-box -no-ellipsis -required">
            <div class="form--check-box-container">
              <input type="checkbox" class="form--check-box" formControlName="eligibility">
            </div>
            {{ text.eligible }}
          </label>

          <button class="gdpr-page--button button -alt-highlight"
                  [attr.title]="text.signDpa"
                  [disabled]="(dpaForm && dpaForm.invalid) || isSigning"
                  (click)="onSubmit()"
          >
            <i class="button--icon icon-loading2" *ngIf="isSigning"></i>
            <span class="button--text" [textContent]="text.signDpa"></span>
          </button>

          <div
            *ngIf="isSigning"
            class="op-loading-indicator"
          >
            <div></div><div></div>
          </div>
        </section>
      </div>

      <!-- Contract signed successfully -->
      <ng-template #signed>
        <div class="gdpr-page--contract-signed" id="contract-signed">
          <op-icon class="icon-checkmark icon-big" [icon-title]="saveTitle"></op-icon>
          <h3 [innerHTML]="text.dpaSigned"></h3>
          <p [innerHTML]="text.downloadText"></p>
          <a class="gdpr-page--button button -highlight" target="_blank" [href]="dpaLink">{{ text.downloadBtn }}</a>
        </div>
      </ng-template>

    </form>
  </section>

  <section class="gdpr-page--related-infos">
    <div class="gdpr-page--related-docs">
      <h3 class="gdpr-page--heading">{{ text.related.label }}</h3>
      <ul class="widget-box--arrow-links">
        <li>
          <a [href]="text.links.serviceAgreement" target="_blank" rel="noreferrer noopener">
            {{ text.related.serviceAgreement }}
          </a>
        </li>
        <li>
          <a [href]="text.links.serviceDescription" target="_blank" rel="noreferrer noopener">
            {{ text.related.serviceDescription }}
          </a>
        </li>
        <li>
          <a [href]="text.links.privacyStatement" target="_blank" rel="noreferrer noopener">
            {{ text.related.privacyStatement }}
          </a>
        </li>
        <li>
          <a [href]="text.links.dpa" target="_blank" rel="noreferrer noopener">
            {{ text.dpa }}
          </a>
        </li>
        <li>
          <a [href]="text.links.subcontractors" target="_blank" rel="noreferrer noopener">
            {{ text.related.subcontractors }}
          </a>
        </li>
        <li>
          <a [href]="text.links.dataSecurity" target="_blank" rel="noreferrer noopener">
            {{ text.related.dataSecurity }}
          </a>
        </li>
      </ul>
    </div>

    <div class="gdpr-page--contact form -bordered">
      <h3 class="gdpr-page--heading" [innerHTML]="text.contact.label"></h3>
      <p [innerHTML]="text.contact.hint"></p>
      <p>
        <b>{{ text.form.email }}:</b><br>
        <a href="mailto:privacy@openproject.com">privacy&#64;openproject.com</a>
      </p>
      <p>
        <b>{{ text.contact.gpgKey }}:</b><br>
        <a [href]="gpgKeyLink">{{ gpgKey }}</a>
      </p>
    </div>
  </section>
</div>
