// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2020 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See docs/COPYRIGHT.rdoc for more details.
// ++

import {
  Component,
  ElementRef,
  Inject,
  ChangeDetectorRef} from "@angular/core";
import {I18nService} from "core-app/core/i18n/i18n.service";
import {OpModalComponent} from "core-app/shared/components/modal/modal.component";
import {OpModalLocalsToken} from "core-app/shared/components/modal/modal.service";
import {OpModalLocalsMap} from "core-app/shared/components/modal/modal.types";
import {GonService} from "core-app/core/gon/gon.service";

@Component({
  selector: 'dpa-modal',
  templateUrl: './dpa.modal.html',
  styleUrls: ['./dpa.modal.sass']
})
export class DPAModal extends OpModalComponent {
  // modal configuration
  public closeOnEscape:boolean = false;
  public closeOnOutsideClick:boolean = false;

  public text = {
    button_close: this.I18n.t('js.button_close'),
    close_popup: this.I18n.t('js.close_popup_title'),
    reviewDPA: this.I18n.t('js.subscription.gdpr.review_dpa')
  };

  public content = (this.Gon.get('dpa') as any).main

  constructor(
    readonly elementRef:ElementRef,
    readonly cdRef:ChangeDetectorRef,
    readonly I18n:I18nService,
    readonly Gon:GonService,
    @Inject(OpModalLocalsToken) public locals:OpModalLocalsMap
  ) {
    super(locals, cdRef, elementRef);
  }

}
