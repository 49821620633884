import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InvoiceService} from '../invoice-form/invoice.service';
import { CountryOption, SubscriptionPeriodOption, SupportPlan } from '../billing-form/billing.service';
import { ToastService } from "core-app/shared/components/toaster/toast.service";

@Injectable()
export class SubscriptionService {
  private _baseApiUrl:string;

  constructor(protected http:HttpClient,
              readonly toastService:ToastService,
              protected invoice:InvoiceService) {
    this._baseApiUrl = (window as any).gon.api_url;
  }

  public subscribeOnline(supportPlan:SupportPlan, userCount:number, currentCountryOption:CountryOption, currentSubscriptionPeriod:SubscriptionPeriodOption) {
    // @ts-ignore
    var cb = Chargebee.getInstance();

    var currentCountryKey = currentCountryOption.key;
    var paymentPeriod = currentSubscriptionPeriod.key;

    cb.openCheckout({
      hostedPage: () => {
        return this.http
          .post("/admin/subscriptions/subscribe", {
            user_count: userCount,
            support_level: supportPlan,
            country: currentCountryKey,
            payment_period: paymentPeriod
          }, { withCredentials: true })
          .toPromise()
          .catch((e:any) => {
            this.toastService.addError(e.error.message || e.message || e);
            console.log(e);
          });
      },
      success: function(pid:any) {
        // This used to be accomplished by passing the `redirect_url` option when
        // generating the checkout URL (SubscriptionsController#hosted_page_checkout).
        // However, that only works if the correct domains are whitelisted in ChargeBee.
        // This is an additional potential point of failure and also won't work with custom domains.
        //
        // At the end of the day it is a very simple redirect as implemented now
        // anyway. So we do that here ourselves instead now since it's sess prone
        // to breaking and supporting custom domains too.
        //
        // The subscribed callback is associating the tenant with the newly booked subscription.
        // The booking will fail unless this is called in development mode.
        // As in the subscription will be created but not associated with the tenant.
        //
        // On production it is ok if this is not called (for instance because the user closes
        // the window right away) because as a backup Augur responds to subscription
        // update webhooks and forwards them to the tenant to make the association after
        // a slight delay. Search for `account_subscription_changed` if you want to see how.
        document.location.href = "/admin/subscriptions/subscribed_callback?state=succeeded&id=" + pid;
      }
    });
  }

  public subscribeOffline(supportPlan:SupportPlan, userCount:number, country:string, period:number) {
    this.sendDataTo(supportPlan, userCount, country, period, this._baseApiUrl + "/invoice");
  }

  public createQuote(supportPlan:SupportPlan, userCount:number, country:string, period:number) {
    this.sendDataTo(supportPlan, userCount, country, period, this._baseApiUrl + "/invoice?quote=1");
  }

  private sendDataTo(supportPlan:SupportPlan, userCount:number, country:string, period:number, url:string) {
    var button = <HTMLInputElement> document.getElementById("button--subscribe");
    button.disabled = true;

    return this.http
      .post(url,
        this.data(supportPlan, userCount, country, period),
        { withCredentials: true })
      .toPromise()
      .then(() => {
        button.disabled = false;
        window.location.href = "/admin/subscriptions/";
      }, (e:any) => {
        button.disabled = false;
        this.toastService.addError(e.error.message || e.message || e);
        console.log(e);
      });
  }

  private data(supportPlan:SupportPlan, userCount:number, country:string, period:number):any {
    var additionalData = {
      user_count: userCount,
      support_level: supportPlan,
      payment_period: period,
      country: country,
    };

    return Object.assign(this.invoice.invoiceData(), additionalData);
  }
}