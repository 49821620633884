<form class="form" (submit)="subscribe($event)" ngNativeValidate>
  <section class="form--section">
    <h3 class="form--section-title"
        [textContent]="text.chosePlan">
    </h3>

    <billing-form
      [quote]="isQuote || isInvoice"
    ></billing-form>
  </section>

  <hr class="form--separator form--separator_limited"/>

  <div class="payment-options">
    <div class="payment-options--images">
      <div class="payment-options--image image--visa" [title]="text.visa"></div>
      <div class="payment-options--image image--master-card" [title]="text.masterCard"></div>
      <div class="payment-options--image image--maestro" [title]="text.maestro"></div>
      <div class="payment-options--image image--american-express" [title]="text.americanExpress"></div>
      <div *ngIf="!isMonthly()" class="payment-options--image image--bank-transfer" [title]="text.bankTransfer"></div>
    </div>

    <span *ngIf="isInvoiceFormAllowed && !displayQuoteForm"
          [textContent]="text.or">
    </span>

    <div class="payment-options--invoice-toggle form--field -trailing-label"
         *ngIf="isInvoiceFormAllowed && !displayQuoteForm">
      <label class="form--label" for="enable-invoice">
        <span [textContent]="text.payPerInvoice + ' '"></span>
        <i [textContent]="text.paymentInfoAnnually"></i>
      </label>
      <div class="form--field-container">
        <div class="form--check-box-container">
          <input id="enable-invoice"
                 type="checkbox"
                 class="form--check-box"
                 (change)="toggleInvoiceFormVisibility($event)"
                 [checked]="this.isInvoice">
        </div>
      </div>
    </div>
  </div>

  <invoice-form *ngIf="displayInvoiceForm || displayQuoteForm"
                [headerTitle]="invoiceFormTitle()"
                [currentCountryOption]="billing.currentCountryOption">
  </invoice-form>

  <button [textContent]="submitButtonText()"
          class="button -highlight"
          id="button--subscribe">
  </button>

  <button *ngIf="!displayInvoiceForm"
          [textContent]="quoteButtonText()"
          class="button"
          id="button--quote"
          (click)="toggleQuoteFormVisibility($event, !displayQuoteForm)">
  </button>
</form>