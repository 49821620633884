// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2018 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++

import {OpenProjectPluginContext} from 'core-app/features/plugins/plugin-context';

export function setupChargebee() {
  // @ts-ignore
  Chargebee.init({site: (window as any).gon.site});

  // @ts-ignore
  var cb = Chargebee.getInstance();

  var close = function() {
    window.location.href = "/admin/subscriptions/updated";
  };

  var subscriptionChanged = function() {
    (window as any).OpenProject.getPluginContext()
      .then((context:OpenProjectPluginContext) => {
        context.runInZone(() => context.services.notifications.addSuccess((window as any).I18n.t('js.subscription.label_subscription_updated')));
        close();
      });
  };

  jQuery("#manage-subscription").click(function() {
    cb.setPortalSession(function() {
      return jQuery.ajax({
        url: "/admin/subscriptions/portal_session",
        method: "POST"
      });
    });

    var portal = cb.createChargebeePortal();

    portal.open({
      close: close,
      subscriptionChanged: subscriptionChanged
    });
  });

  jQuery("#upgrade-subscription").click(function() {
    cb.setPortalSession(function() {
      return jQuery.ajax({
        url: "/admin/subscriptions/portal_session",
        method: "POST"
      });
    });

    var portal = cb.createChargebeePortal();

    portal.open(
      {
        close: close,
        subscriptionChanged: subscriptionChanged
      },
      {
        // @ts-ignore
        sectionType: Chargebee.getPortalSections().EDIT_SUBSCRIPTION,
        params: {
          subscriptionId: JSON.parse((window as any).gon.subscription).id
        }
      });
  });

  jQuery("#available-users-link").click(function() {
    cb.setPortalSession(function() {
      return jQuery.ajax({
        url: "/admin/subscriptions/portal_session",
        method: "POST"
      });
    });

    var portal = cb.createChargebeePortal();

    portal.openSection(
      {
        // @ts-ignore
        sectionType: Chargebee.getPortalSections().SUBSCRIPTION_DETAILS,
        params: {
          subscriptionId: JSON.parse((window as any).gon.subscription).id
        }
      },
      {
        close: close,
        subscriptionChanged: subscriptionChanged
      });
  });
}