import { Edition, SupportPlan } from './billing.service';

export const pricingPage = 'https://www.openproject.org/pricing/';

export const basePrices = {
  classic: {
    basic: {
      yearly: {
        us: 7.25,
        default: 5.95,
      },
      monthly: {
        us: 8.50,
        default: 6.95,
      },
    },
    professional: {
      yearly: {
        us: 13.50,
        default: 10.95,
      },
    },
    premium: {
      yearly: {
        us: 19.5,
        default: 15.95,
      }
    }
  },
  bim: {
    basic: {
      yearly: {
        us: 8.5,
        default: 6.95,
      },
      monthly: {
        us: 9.75,
        default: 7.95,
      },
    },
    professional: {
      yearly: {
        us: 14.75,
        default: 11.95,
      },
    },
    premium: {
      yearly: {
        us: 20.75,
        default: 16.95,
      }
    }
  },
}