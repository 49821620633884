<div class="trial-activate">
  <div class="trial-wrapper">
    <div class="trial-activate--image-wrapper">
      <svg width="600" height="600" viewBox="0 0 600 600" alt="trial edition image" class="trial-activate--image"
           preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
        <image [attr.href]="trialActivateImageSrc" height="600" width="600"/>
        <g transform="translate(155 210)">
          <text style="fill:#1a67a2; font-family:Lato-Black, Lato; font-size:30px; font-weight:800; opacity:.2;">
            <tspan x="0" y="0">{{welcome}}</tspan>
          </text>
        </g>
      </svg>
    </div>
    <div class="trial-activate--content">
      <div class="trial-activate--header">
        <h2 [innerHTML]="text.welcome"></h2>
        <p [innerHTML]="text.intro"></p>
        <h3 class="form--section-title" [innerHTML]="text.prompt"></h3>
      </div>
    </div>
  </div>
  <div class="trial-activate--form">
    <trial-form
      [username]="username"
      [passwordMinLength]="passwordMinLength"
      [passwordRequiredClassCount]="passwordRequiredClassCount"
      [passwordActiveRules]="passwordActiveRules"
      (onFirstNameChange)="firstNameChange($event)"
    ></trial-form>
  </div>
</div>
