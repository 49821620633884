import {Injectable} from "@angular/core";

@Injectable()
export class InvoiceService {
  public invoiceData():any {
    var read = this.read;
    var vatNumber = read("invoice-vat-id-number");
    var company = read("invoice-company").trim();

    return {
      customer: {
        first_name: read("invoice-first-name"),
        last_name: read("invoice-last-name"),
        email: read("invoice-email"),
        company: (company.length > 0 ? company : undefined),
        vat_number: (vatNumber.length > 0 ? vatNumber : undefined)
      },
      billing_address: {
        first_name: read("invoice-first-name"),
        last_name: read("invoice-last-name"),
        line1: read("invoice-billing-address"),
        zip: read("invoice-zip-code"),
        city: read("invoice-city"),
        country: read("subscription-country")
      }
    }
  }

  private read(id:string):string {
    const element = (document.getElementById(id) as HTMLInputElement);

    return _.get(element, 'value', '');
  }
}
