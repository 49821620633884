<div
    class="spot-modal op-dpa-modal"
    data-indicator-name="modal"
    tabindex="0"
>

  <div class="spot-modal--header" [textContent]="text.reviewDPA"></div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body">
    <div class="op-dpa-modal--content">
      <div [innerHTML]="content"></div>
    </div>
  </div>
  <div class="spot-modal--footer spot-action-bar hidden-for-mobile">
    <div class="spot-action-bar--right">
      <button
          class="button button_no-margin spot-modal--cancel-button spot-action-bar--action"
          (click)="closeMe($event)"
          [textContent]="text.button_close"
          [attr.title]="text.button_close"
      >
      </button>
    </div>
  </div>
</div>
