// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2020 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See docs/COPYRIGHT.rdoc for more details.
// ++

import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {I18nService} from "core-app/core/i18n/i18n.service";

export const teaserComponentSelector = 'buy-now-teaser';

@Component({
  templateUrl: './buy-now-teaser.component.html',
  selector: teaserComponentSelector,
  styleUrls: ['./buy-now-teaser.component.sass'],
  encapsulation: ViewEncapsulation.None

})
export class BuyNowTeaserComponent implements OnInit {
  public visible:boolean = false;
  public small:boolean = false;
  public buttonSized:boolean = false;
  public trialDaysLeft:number = 0;
  public instanceTrialUsedPercentage = '';

  public adminLink:string = '/admin/subscriptions/new';

  public text = {
    daysLeft: this.I18n.t('js.subscription.plan.days_left'),
    buyNow: this.I18n.t('js.subscription.label_buy_now')
  };

  constructor(private elementRef:ElementRef,
              private I18n:I18nService) {
  }

  ngOnInit() {
    this.visible = this.elementRef.nativeElement.dataset['visible'] === 'true';
    this.small = this.elementRef.nativeElement.dataset['small'] === 'true';
    this.buttonSized = this.elementRef.nativeElement.dataset['buttonSized'] === 'true';
    this.trialDaysLeft = this.elementRef.nativeElement.dataset['trialDaysLeft'];
    this.instanceTrialUsedPercentage = this.elementRef.nativeElement.dataset['instanceTrialUsedPercentage'] + '%';
  }
}

