// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++
import { Component, Injector, OnInit } from "@angular/core";
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { I18nService } from "core-app/core/i18n/i18n.service";
import { OpModalService } from "core-app/shared/components/modal/modal.service";
import { GonService } from "core-app/core/gon/gon.service";
import { PathHelperService } from "core-app/core/path-helper/path-helper.service";
import { DPAModal } from "./dpa-modal/dpa.modal";
import { ToastService } from "core-app/shared/components/toaster/toast.service";

@Component({
  selector: 'gdpr-page-component',
  templateUrl: './gdpr-page.component.html',
  styleUrls: ['./gdpr-page.component.sass']
})
export class GDPRPageComponent implements OnInit {
  readonly gpgKey:string = "BDCF E01E DE84 EA19 9AE1 72CE 7D66 9C6D 4753 3958";

  public dpaForm = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    organization: ['', Validators.required],
    email: ['', [Validators.email]],
    address: ['', Validators.required],
    zip: ['', Validators.required],
    city: ['', Validators.required],
    country: ['', Validators.required],
    eligibility: [false, Validators.requiredTrue],
  });
  public availableCountries = [];

  public isSigning:boolean = false;
  public isSigned:boolean;
  public dpaLink:string = '';

  public text = {
    dpa: this.I18n.t('js.subscription.gdpr.label_dpa'),
    dpaSigned: this.I18n.t('js.subscription.gdpr.dpa_signed'),
    downloadText: this.I18n.t('js.subscription.gdpr.dpa_download_text'),
    downloadBtn: this.I18n.t('js.subscription.gdpr.dpa_download_btn'),
    eligible: this.I18n.t('js.subscription.gdpr.sign_eligible'),
    introduction: this.I18n.t('js.subscription.gdpr.dpa_introduction_text'),
    invalidMail: this.I18n.t('js.subscription.gdpr.invalid_email'),
    reviewDPA: this.I18n.t('js.subscription.gdpr.review_dpa'),
    signDpa: this.I18n.t('js.subscription.gdpr.sign_dpa'),
    form: {
      label: this.I18n.t('js.subscription.gdpr.contract_form.label'),
      firstName: this.I18n.t('js.subscription.billing_info.first_name'),
      lastName: this.I18n.t('js.subscription.billing_info.last_name'),
      organization: this.I18n.t('js.subscription.billing_info.organization'),
      email: this.I18n.t('js.subscription.gdpr.contract_form.email'),
      address: this.I18n.t('js.subscription.billing_info.billing_address'),
      zipCode: this.I18n.t('js.subscription.billing_info.zip_code'),
      city: this.I18n.t('js.subscription.billing_info.city'),
      country: this.I18n.t('js.subscription.plan.country')
    },
    contact: {
      label: this.I18n.t('js.subscription.gdpr.contact.label'),
      hint: this.I18n.t('js.subscription.gdpr.contact.contact_hint'),
      gpgKey: this.I18n.t('js.subscription.gdpr.contact.gpg_key')
    },
    related: {
      label: this.I18n.t('js.subscription.gdpr.related.label'),
      serviceAgreement: this.I18n.t('js.subscription.gdpr.related.service_agreement'),
      serviceDescription: this.I18n.t('js.subscription.gdpr.related.service_description'),
      privacyStatement: this.I18n.t('js.subscription.gdpr.related.privacy_statement'),
      subcontractors: this.I18n.t('js.subscription.gdpr.related.subcontractors'),
      dataSecurity: this.I18n.t('js.subscription.gdpr.related.data_security')
    },
    links: {
      serviceAgreement: this.I18n.t('js.subscription.gdpr.links.service_agreement'),
      serviceDescription: this.I18n.t('js.subscription.gdpr.links.service_description'),
      privacyStatement: this.I18n.t('js.subscription.gdpr.links.privacy_statement'),
      dpa: this.I18n.t('js.subscription.gdpr.links.dpa'),
      subcontractors: this.I18n.t('js.subscription.gdpr.links.subcontractors'),
      dataSecurity: this.I18n.t('js.subscription.gdpr.links.data_security')
    }
  };

  public constructor(protected I18n:I18nService,
                     protected formBuilder:FormBuilder,
                     readonly injector:Injector,
                     protected opModalService:OpModalService,
                     readonly Gon:GonService,
                     protected http:HttpClient,
                     readonly pathHelper:PathHelperService,
                     protected toastService:ToastService) {
  }

  public ngOnInit() {
    this.dpaLink = this.Gon.get('dpa_link') as any;

    if (this.dpaLink) {
      // If user already signed DPA, load confirmation screen
      this.isSigned = true;
    } else {
      // Get user data from gon and pre-fill form
      const userdata = this.Gon.get('userdata') as any;

      if (userdata) {
        this.prefillForm(userdata);
        this.availableCountries = this.Gon.get('available_countries') as any;
      }

      this.text.links.subcontractors = this.I18n.t('js.subscription.gdpr.links.subcontractors');
    }

    setTimeout(() => this.openDPAModal(), 2000);
  }

  public get gpgKeyLink() {
    return "https://keys.openpgp.org/vks/v1/by-fingerprint/" + this.gpgKey.replace(/ /g, '');
  }

  public openDPAModal() {
    this.opModalService.show(DPAModal, this.injector);
  }

  // Send form to backend and get DPA download link
  // Save link in db to remember if a user has already signed the DPA
  public onSubmit() {
    if (this.dpaForm.valid) {
      const request = this.dpaForm.value;

      this.isSigning = true;

      this.http
        .post(this.pathHelper.appBasePath + '/admin/gdpr/sign', request, { withCredentials: true })
        .toPromise()
        .then((res:any) => {
          this.dpaLink = res["location"];
          // Show confirmation screen with download button
          this.isSigned = true;
          this.isSigning = false;
        })
        .catch((resp:HttpErrorResponse) => {
          this.toastService.addWarning(resp.error.description || I18n.t('js.error.internal'));
        });
    }
  }

  // Pre-fill form on init with user data from Chargebee
  private prefillForm(data:any) {
    this.dpaForm.patchValue({
      firstName: data.first_name,
      lastName: data.last_name,
      organization: data.organization,
      email: data.email,
      address: data.address,
      zip: data.zip_code,
      city: data.city,
      country: data.country
    });
  }
}
