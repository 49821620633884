<form
  [formGroup]="trialForm"
  (ngSubmit)="onSubmit($event)"
  class="spot-container trial-form">
  <input type="hidden" name="token">


  <spot-form-field
    [label]="text.username"
    [required]="true"
  >
    <input slot="input" class="trial-form--username-input" name="username" [value]="username" readonly>
<!--    <p-->
<!--      class="spot-form-field&#45;&#45;description"-->
<!--      slot="action"-->
<!--      [textContent]="text.changeLater">-->
<!--    </p>-->
  </spot-form-field>

  <div class="trial-form--spot-columns">

    <spot-form-field
      [label]="text.firstName"
      [required]="true"
    >
      <spot-text-field
        slot="input"
        name="firstname"
        formControlName="firstname"
        [showClearButton]="false"
      ></spot-text-field>
      <div
        slot="errors"
        class="spot-form-field--error"
        *ngIf="firstNameControl.errors?.required"
        [textContent]="text.fieldRequired"
      ></div>
    </spot-form-field>

    <spot-form-field
      [label]="text.lastName"
      [required]="true"
    >
      <spot-text-field
        slot="input"
        formControlName="lastname"
        name="lastname"
        [showClearButton]="false"
      ></spot-text-field>
      <div
        slot="errors"
        class="spot-form-field--error"
        *ngIf="lastNameControl.errors?.required"
        [textContent]="text.fieldRequired"
      ></div>
    </spot-form-field>

  </div>

  <spot-form-field
    [required]="true"
    [label]="text.password"
  >
    <div
      class="spot-text-field trial-form--spot-password-field"
      slot="input">
      <input
        class="spot-text-field--input trial-form--spot-password-field--input"
        name="password"
        autocomplete="off"
        formControlName="password"
        type="password"
      >
    </div>
    <div
      slot="errors"
      class="spot-form-field--error"
      *ngIf="passwordControl.errors?.required"
      [textContent]="text.fieldRequired"
    ></div>
    <div
      slot="errors"
      class="spot-form-field--error"
      *ngIf="passwordControl.errors?.minlength"
      [textContent]="dynamicText.passwordMinLength"
    ></div>
    <div
      slot="errors"
      class="spot-form-field--error"
      *ngIf="passwordControl.errors?.passwordStrength"
      [textContent]="dynamicText.passwordRequirementHint"
    ></div>
  </spot-form-field>

  <div class="trial-form--spot-checkbox">
    <spot-selector-field
      class="spot-action-bar--action"
      [label]="text.newsletter"
    >
      <spot-checkbox slot="input" name="newsletter" formControlName="newsletter"></spot-checkbox>
      <span
        class="spot-form-field--description"
        slot="help-text"
        [textContent]="text.newsletterInfo">
      </span>
    </spot-selector-field>
  </div>

  <div class="spot-action-bar spot-action-bar_transparent">
    <div class="spot-action-bar--left spot-action-bar--always-left">
      <button
        type="submit"
        class="button -highlight spot-action-bar--action"
        [textContent]="text.submit"
      ></button>
    </div>
  </div>
</form>
