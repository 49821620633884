<i class="icon-context icon-medal"
   [title]="text.status">
</i>

<span *ngIf="isTrial"
      class="subscription-status"
      [innerHtml]="text.noActiveSubscription(upgradeUrl)">
</span>

<span *ngIf="!isTrial"
      class="subscription-status"
      [ngClass]="status"
      [innerHtml]="subscriptionText()">
</span>

<b *ngIf="withScheduledChanges"
   [innerHtml]="text.subscriptionStatusUpdated">
</b>