// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++
import { Input, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import { PathHelperService } from 'core-app/core/path-helper/path-helper.service';
import { ToastService } from 'core-app/shared/components/toaster/toast.service';

import {
  UntypedFormControl, FormControl,
  UntypedFormGroup,
  Validators,
  AbstractControl, ValidationErrors, ValidatorFn,
} from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

interface PostResponse {
  error?:string;
  redirect?:string;
}

const PASSWORD_RULES:{ [name:string]:RegExp } = {
  'uppercase': /[A-Z]+/,
  'lowercase': /[a-z]+/,
  'special': /[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]/,
  'numeric': /[0-9]+/,
};

export function createPasswordStrengthValidator(passwordActiveRules:string[], passwordRequiredClassCount:number,
  passwordMinLength:number):ValidatorFn {
  return (control:AbstractControl):ValidationErrors|null => {
    const value = control.value;
    if (!value || value.length < passwordMinLength) {
      return null;
    }
    let numberRequiredClassedSet = 0;
    passwordActiveRules.forEach(rule => {
      const regex = PASSWORD_RULES[rule];
      if (regex !== undefined && regex.test(value)) {
        numberRequiredClassedSet++;
      }
    })

    const passwordValid = numberRequiredClassedSet >= passwordRequiredClassCount;
    return !passwordValid ? { passwordStrength: true } : null;
  }
}

@Component({
  selector: 'trial-form',
  templateUrl: './trial-form.html',
  styleUrls: ['./trial-form.sass'],
})
export class TrialFormComponent implements OnInit {
  @Input() public username:string;
  @Input() public passwordMinLength:number = 10;
  @Input() public passwordRequiredClassCount:number = 0;
  @Input() public passwordActiveRules:string;
  @Output() public onFirstNameChange = new EventEmitter<string>();

  public text = {
    fieldRequired: this.I18n.t('js.subscription.trial.field_required'),
    changeLater: this.I18n.t('js.subscription.trial.change_later'),
    username: this.I18n.t('js.subscription.trial.username'),
    firstName: this.I18n.t('js.subscription.trial.first_name'),
    lastName: this.I18n.t('js.subscription.trial.last_name'),
    password: this.I18n.t('js.subscription.trial.password'),
    newsletter: this.I18n.t('js.subscription.trial.newsletter'),
    newsletterInfo: this.I18n.t('js.subscription.trial.newsletter_info'),
    submit: this.I18n.t('js.subscription.trial.submit'),
  };
  public dynamicText = {
    passwordRequirementHint: '',
    passwordMinLength: '',
  };

  public trialForm:UntypedFormGroup;

  constructor(
    private readonly I18n:I18nService,
    private readonly http:HttpClient,
    private readonly pathHelper:PathHelperService,
    private readonly toastService:ToastService,
  ) {}

  ngOnInit():void {
    this.initPasswordRulesHint();
    this.initForm();
    this.applyToken();
    this.registerFirstNameChange();
  }

  initPasswordRulesHint():void {
    this.dynamicText.passwordMinLength = this.I18n.t('js.subscription.trial.password_hint.min_length', { min_length: this.passwordMinLength });
    this.dynamicText.passwordRequirementHint = this.I18n.t('js.subscription.trial.password_hint.weak', {
      min_count: this.passwordRequiredClassCount,
      all_count: this.passwordActiveRules.length,
      rules: this.passwordActiveRules.split(',').map(rule => this.I18n.t(`js.subscription.trial.password_hint.rules.${rule}`)).join(', '),
    });
  }

  initForm():void {
    this.trialForm = new UntypedFormGroup({
      password: new UntypedFormControl(null, [Validators.required, Validators.minLength(this.passwordMinLength),
        createPasswordStrengthValidator(this.passwordActiveRules.split(','), this.passwordRequiredClassCount, this.passwordMinLength)]),
      lastname: new UntypedFormControl(null, [Validators.required]),
      firstname: new UntypedFormControl(null, Validators.compose([Validators.required])),
      token: new UntypedFormControl(null, [Validators.required]),
      newsletter: new UntypedFormControl(false, []),
    });
  }

  applyToken():void {
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get('token') || '';
    const input = this.trialForm.get('token');
    input?.setValue(token, { emitEvent: false });
  }

  registerFirstNameChange():void {
    this.firstNameControl.valueChanges
      .subscribe((selectedValue:string) => {
        this.onFirstNameChange.emit(selectedValue);
      });
  }

  get passwordControl() {
    return this.trialForm.get('password')!;
  }

  get lastNameControl() {
    return this.trialForm.get('lastname')!;
  }

  get firstNameControl() {
    return this.trialForm.get('firstname')!;
  }

  onSubmit(event:Event) {
    event.preventDefault();
    if (this.trialForm.invalid) {
      this.trialForm.markAsDirty();
      return;
    }
    this.onValidatedSubmit();
  }

  reportError(error:string|HttpErrorResponse) {
    console.error(error);
    this.toastService.addError(error);
  }

  onValidatedSubmit() {
    const formData = this.trialForm.value;
    this.http.post<PostResponse>(
      `${this.pathHelper.appBasePath}/trial/activate`,
      formData, { withCredentials: true })
      .toPromise()
      .then(result => {
        if (result?.error) {
          return this.reportError(result.error);
        }
        if (result?.redirect) {
          window.location.href = result.redirect as string;
        }
      })
      .catch((e:HttpErrorResponse) => {
        const errorResponse = e.error
        this.reportError(errorResponse.message || e.message || e)
      });
  }
}
