// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++
import {Component, OnInit, Input} from "@angular/core";
import {I18nService} from "core-app/core/i18n/i18n.service";
import {vatCountries} from "./invoice.const";
import {CountryOption} from "../billing-form/billing.service";

@Component({
    selector: 'invoice-form',
    templateUrl: './invoice-form.html'
})
export class InvoiceFormComponent implements OnInit {
  @Input() public currentCountryOption:CountryOption;
  @Input() public headerTitle:string;
  @Input() public showTermsOfServiceTooltip:boolean = true;

  public availableGenderOptions:string[];
  public showVatForm:boolean;

  public text = {
      title: this.I18n.t('js.subscription.billing_info.title.title'),
      firstName: this.I18n.t('js.subscription.billing_info.first_name'),
      lastName: this.I18n.t('js.subscription.billing_info.last_name'),
      company: this.I18n.t('js.subscription.billing_info.organization'),
      billingAddress: this.I18n.t('js.subscription.billing_info.billing_address'),
      zipCode: this.I18n.t('js.subscription.billing_info.zip_code'),
      city: this.I18n.t('js.subscription.billing_info.city'),
      country: this.I18n.t('js.subscription.plan.country'),
      email: this.I18n.t('js.subscription.billing_info.email'),
      vatIdNumber: this.I18n.t('js.subscription.billing_info.vat_id_number'),
      agree: this.I18n.t('js.subscription.billing_info.agree'),
      acceptedTerms: this.I18n.t('js.subscription.billing_info.acceptedTerms'),
  };

  public constructor(protected I18n:I18nService) {
      this.availableGenderOptions = [I18n.t('js.subscription.billing_info.title.ms'), I18n.t('js.subscription.billing_info.title.mr')];
  }

  public ngOnInit() {
      this.updateShowVatForm();
  }

  public get showVat() {
      this.updateShowVatForm();
      return this.showVatForm;
  }

  public updateShowVatForm() {
      // Based on the fact that the invoice is only shown for the EU, Switzerland and the US
      this.showVatForm = vatCountries.includes(this.currentCountryOption.key);
  }
}
