<div *ngIf="visible"
     class="teaser-container"
     [ngClass]="{'-small': small, '-button-sized': buttonSized}">
  <a [href]="adminLink"
     class="teaser-link">

    <span class="days-remaining large-text">{{ trialDaysLeft }}</span>
    <span class="days-remaining--text -small-font">{{ text.daysLeft }}</span>
    <span class="button-yellow button">{{ text.buyNow }}</span>

    <div *ngIf="!small"
         class="bar-container">
      <div class="bar-progress"
           [ngStyle]="{'width': instanceTrialUsedPercentage}"></div>
      <div class="bar-bullet"></div>
    </div>
  </a>
</div>