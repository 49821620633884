// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++
import {Component, OnInit, Input} from "@angular/core";
import {I18nService} from "core-app/core/i18n/i18n.service";
import { BillingService, Edition, SupportPlan } from "./billing.service";
import { basePrices, pricingPage } from './billing.const';

@Component({
    selector: 'billing-form',
    templateUrl: './billing-form.html',
    styleUrls: ['./billing-form.sass'],
})
export class BillingFormComponent implements OnInit {
    private _baseApiUrl:string;

    @Input() public enterpriseEdition:boolean = false;
    @Input() public quote:boolean = false;

    public availableUserCounts:{name:string, key:number}[] = [];
    public availableSupportPlanOptions:{name:string, key:SupportPlan, minimumUsers:number}[] = [];
    public billingAmount:number = 357;

    public text = {
        users: this.I18n.t('js.subscription.users'),
        period: this.I18n.t('js.subscription.label_period'),
        support: this.I18n.t('js.subscription.label_support'),
        bimEdition: this.I18n.t('js.subscription.label_bim_edition'),
        members: this.I18n.t('js.subscription.members'),
        unlimited: this.I18n.t('js.subscription.unlimited'),
        country: this.I18n.t('js.subscription.plan.country'),
        amount: this.I18n.t('js.subscription.payment.amount'),
        vat_info: this.I18n.t('js.subscription.payment.vat_info'),
        supportPlans: {
          basic: this.I18n.t('js.subscription.support.basic'),
          professional: this.I18n.t('js.subscription.support.professional'),
          premium: this.I18n.t('js.subscription.support.premium'),
          pricePerUser: (price:string):string => this.I18n.t('js.subscription.payment.price_per_user_month', { price }),
        },
        subscriptionPeriods: {
          oneMonth: this.I18n.t('js.subscription.periods.1_month'),
          oneYear: this.I18n.t('js.subscription.periods.1_year'),
          twoYear: this.I18n.t('js.subscription.periods.2_years'),
          threeYear: this.I18n.t('js.subscription.periods.3_years'),
          fourYear: this.I18n.t('js.subscription.periods.4_years'),
          fiveYear: this.I18n.t('js.subscription.periods.5_years'),
        },
    };

    public get allowBimEditionToggle():boolean {
      // Hidden on by default, only if we configure augur we want to show it.
      // This means we configured an instance to serve the form to be downloaded
      // for the static form which uses augur to create enterprise quotes.
      return this.quote && this._baseApiUrl.indexOf("augur") != -1;
    }

    public constructor(
      protected I18n:I18nService,
      readonly billing:BillingService,
    ) {
        this._baseApiUrl = (window as any).gon.api_url;
    }

    public ngOnInit() {
      // Set available countries
      this.billing.availableCountryOptions = JSON.parse((window as any).gon.available_countries);
      this.billing.updateCountryOptionByKey(navigator.language.toUpperCase());

      // Set available subscription periods
      this.billing.availableSubscriptionPeriods = [
        { name: this.text.subscriptionPeriods.oneYear, key: 12, savedMonths: 0 },
        { name: this.text.subscriptionPeriods.twoYear, key: 24, savedMonths: 5 },
        { name: this.text.subscriptionPeriods.threeYear, key: 36, savedMonths: 8 },
        { name: this.text.subscriptionPeriods.fourYear, key: 48, savedMonths: 11 },
        { name: this.text.subscriptionPeriods.fiveYear, key: 60, savedMonths: 15 },
      ];
      this.billing.currentSubscriptionPeriod = this.billing.availableSubscriptionPeriods[0];

      // Set available support plans
      this.updateAvailableSupportPlans();
      this.checkForSupportPlanLimitations();

      this.prefillForm();
      this.updateBillingInformation();
    }

    public prefillForm() {
        const queryParams = new URLSearchParams(window.location.search)
        let country = queryParams.get('country');
        let users = queryParams.get('users');
        let edition = queryParams.get('edition');
        let period = queryParams.get('period');
        let support = queryParams.get('support');

        if (country && country.match(/(\w\w)/)) {
          this.setCountry(country.toUpperCase());
        }

        if (users && Number(users)) {
            this.billing.userCount = parseInt(users);
        }

        const allowedPeriods = ['1','12','24','36','48','60'];
        if (period && allowedPeriods.indexOf(period) !== -1) {
            let months = parseInt(period);

            this.billing.updateSubscriptionPeriodByKey(months);
        }

        let allowedSupportPlans = ['professional','basic'];
        if (this.enterpriseEdition) {
          allowedSupportPlans.push('premium');
        }
        if (support && allowedSupportPlans.indexOf(support) !== -1) {
            this.billing.currentSupportPlan = support as SupportPlan;
        }

        const allowedEdition = ['bim','classic'];
        if (edition && allowedEdition.indexOf(edition) !== -1) {
            this.billing.edition = edition as Edition;
        }

        if (country || users || period || support || edition) {
          this.updateBillingInformation();
        }
    }

    public updateUserCount(newCount:string) {
        if(this.billing.userCount !== parseInt(newCount)) {
            this.billing.userCount = parseInt(newCount);
            this.updateBillingInformation();
        }
    }

    public updateSubscriptionPeriod(newPeriod:number) {
        if(this.billing.currentSubscriptionPeriod.key !== newPeriod) {
            this.billing.updateSubscriptionPeriodByKey(newPeriod);
            this.updateBillingInformation();
        }
    }

    public updateSupportPlan(newPlan:SupportPlan) {
        if(this.billing.currentSupportPlan !== newPlan) {
            this.billing.currentSupportPlan = newPlan;
            this.checkForSupportPlanLimitations();
            this.updateBillingInformation();
        }
    }

    public updateEdition(isBimEdition:boolean) {
        if(isBimEdition && this.billing.edition !== 'bim') {
            this.billing.edition = 'bim';
            this.updateBillingInformation();
        } else if (!isBimEdition && this.billing.edition !== 'classic') {
          this.billing.edition = 'classic';
          this.updateBillingInformation();
        }
    }

    public bimEditionCharge():string {
      const country = this.billing.currentCountryOption.key === 'US' ? 'us' : 'default';
      const standardPrice = basePrices.classic.basic.yearly[country];
      const bimPrice = basePrices.bim.basic.yearly[country];
      const additionalBimCharge = this.priceWithCurrency(bimPrice - standardPrice);

      return this.text.supportPlans.pricePerUser(additionalBimCharge);
    }

    public updateCountryOption(optionKey:string) {
        this.updateCountry(optionKey);
    }

    public setCountry(countryKey:string) {
      this.billing.updateCountryOptionByKey(countryKey);
    }

    public updateCountry(countryKey:string) {
        this.setCountry(countryKey);
        this.updateBillingInformation();
    }

    public get displayYearlySavingsText() {
      return this.isYearly() && this.billing.currentSubscriptionPeriod.savedMonths > 0;
    }

    public get yearlySavingsText() {
      const amount = Number(this.billing.basePrice) * Number(this.billing.userCount) * this.billing.currentSubscriptionPeriod.savedMonths;
      return this.I18n.t('js.subscription.payment.saving_info_money', {
        amount:  `${amount} ${this.billing.currency}`});
    }

    public get yearlySavedMonthsText() {
      return this.I18n.t('js.subscription.payment.saving_info_month', { n: this.billing.currentSubscriptionPeriod.savedMonths });
    }

    public get formattedBillingAmount() {
        return this.billingAmount.toLocaleString(this.billing.currentCountryOption.key) + ' ' + this.billing.currency;
    }

    public pricingURL():string {
      const country = this.billing.currentCountryOption.key;
      const currency = this.billing.currency;
      const users = this.billing.userCount;
      const period = this.billing.currentSubscriptionPeriod.key;
      const support = this.billing.currentSupportPlan;
      const edition = this.billing.edition;
      const mode = this.enterpriseEdition ? 'onpremises' : 'cloud';

      return `${pricingPage}?country=${country}&users=${users}&period=${period}&support=${support}&edition=${edition}&mode=${mode}&currency=${currency}`
    }

    public updateBillingInformation() {
        if(this.billing.currentSubscriptionPeriod && this.billing.userCount && this.billing.currentCountryOption ) {
            let xmlhttp = new XMLHttpRequest();
            xmlhttp.onreadystatechange = function(this:any) {
                if (xmlhttp.readyState == 4 && xmlhttp.status == 200){
                    let response = JSON.parse(xmlhttp.response);

                    this.billingAmount = response.amount / 100;
                    this.billing.currency = response.currency;

                    this.updateAvailableSupportPlans();
                }
            }.bind(this);

            xmlhttp.open("GET", this.getEstimateUrl(), true);

            xmlhttp.send();
        }
    }

    private getEstimateUrl():string {
      let url = this._baseApiUrl + '/estimate' +
        '?user_count=' + this.billing.userCount +
        '&support_level=' + this.billing.currentSupportPlan +
        '&country=' + this.billing.currentCountryOption.key +
        '&payment_period=' + this.billing.currentSubscriptionPeriod.key +
        '&edition=' + this.billing.edition +
        '&enterprise_edition=' + this.enterpriseEdition;

      return url;
    }

    private checkForSupportPlanLimitations():void {
      this.setUserCountOptions();
      this.checkForMonthlySubscriptionPeriod();
    }

    private setUserCountOptions():void {
      this.availableUserCounts = [];

      // Set available user options
      const minimumUsers = this.availableSupportPlanOptions.filter((option) => option.key === this.billing.currentSupportPlan)[0].minimumUsers;

      for(var i = minimumUsers; i < 250; i += 5) {
        this.availableUserCounts.push({name: i + ' ' + this.text.members, key: i});
      }
      this.availableUserCounts.push({name: this.text.unlimited, key: 250});

      if (this.billing.userCount < minimumUsers) {
        this.billing.userCount = minimumUsers;
      }
    }

    private checkForMonthlySubscriptionPeriod():void {
      if (this.billing.currentSupportPlan === 'basic') {
        this.billing.availableSubscriptionPeriods.unshift(
          { name: this.text.subscriptionPeriods.oneMonth, key: 1, savedMonths: 0 }
        )

      } else {
        this.billing.availableSubscriptionPeriods = this.billing.availableSubscriptionPeriods.filter((period) => {
          return period.key !== 1;
        });
        if (this.billing.currentSubscriptionPeriod.key === 1) {
          this.billing.updateSubscriptionPeriodByKey(12);
        }
      }
    }

    private isYearly():boolean {
      return this.billing.currentSubscriptionPeriod.key >= 12;
    }

    private updateAvailableSupportPlans():void {
      const period = this.isYearly() ? 'yearly' : 'monthly';
      const country = this.billing.currentCountryOption.key === 'US' ? 'us' : 'default';
      const edition = this.billing.edition;

      if (this.billing.currentSupportPlan === 'basic') {
        this.billing.basePrice = basePrices[edition].basic[period][country];
      } else {
        this.billing.basePrice = basePrices[edition][this.billing.currentSupportPlan].yearly[country];
      }

      // Set available support plans
      this.availableSupportPlanOptions = [];

      if (this.enterpriseEdition) {
        this.availableSupportPlanOptions.push({
          name: `${this.text.supportPlans.premium} ${this.text.supportPlans.pricePerUser(
            this.priceWithCurrency(basePrices[edition].premium.yearly[country])
          )}`,
          key: "premium",
          minimumUsers: 100,
        });
      }

      this.availableSupportPlanOptions.push({
        name: `${this.text.supportPlans.professional} ${this.text.supportPlans.pricePerUser(
          this.priceWithCurrency(basePrices[edition].professional.yearly[country])
        )}`,
        key: "professional",
        minimumUsers: 25,
      });
      this.availableSupportPlanOptions.push({
        name: `${this.text.supportPlans.basic} ${this.text.supportPlans.pricePerUser(
          this.priceWithCurrency(basePrices[edition].basic[period][country])
        )}`,
        key: "basic",
        minimumUsers: 5,
      });
    }

    private priceWithCurrency(price:number):string {
      return `${price} ${this.billing.currency}`;
    }
}
